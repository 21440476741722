<template>
 <div class="info">
    <top-bar :title="'添加活动'" :left="true"></top-bar>

     <van-popup v-model="typeShow" position="bottom" style="max-height: 50%;">
       <!-- 选择 -->
      <div class="typeCheck">
        <span @click="typeShow = false">取消</span>
        <span>请选择</span>
        <span @click="typeConfirm">确定</span>
      </div>
      <!-- 复选框 -->
     <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
        <van-cell-group v-for="(item, index) in typeList" :key="index">
          <van-cell  :title="item.label"  @click="toggle(index)">
            <template #icon>
              <van-checkbox :name="item" ref="checkboxes"/><van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'" @click.stop="childrenVisible = !childrenVisible"/>
            </template>
          </van-cell>
          <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange" v-model="childrenValue">
            <van-cell-group v-for="(items, i) in item.children" :key="i">
              <van-cell  :title="items.label"  @click="toggleChildren(i)">
                <template #icon>
                  <van-checkbox :name="items" ref="childrenBoxes"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </van-cell-group>
      </van-checkbox-group>
<!--      <van-picker title="标题" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfirm" @cancel="typeShow = !typeShow" />-->
    </van-popup>

     <!-- 弹出层 -->
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="datetime" title="选择年月日" :min-date="new Date(2020, 0, 1)" :max-date="new Date(2025, 0, 1)"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="datetime" title="选择年月日" :min-date="new Date(2020, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <!-- 选择参与对象 -->
    <van-popup v-model="surveyObjectShow" position="bottom"  >
      <van-picker title="请选择" value-key="label" show-toolbar :columns="surveyObjectOptions" @confirm="surveyObjectConfirm" @cancel="surveyObjectShow = !surveyObjectShow" />
    </van-popup>

  <!-- 所属机构 -->
   <van-popup v-model="communityShow" position="bottom"  >
     <van-picker title="请选择" cancel-button-text="重置" value-key="label" show-toolbar :columns="communityList" @confirm="communityConfim" @cancel="communityShow = !communityShow" />
   </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field clearable clear-trigger="always" v-model="dataForm.title" label="活动名称" placeholder="请输入" input-align="right"/>
      <van-field clearable clear-trigger="always" v-model="dataForm.address" label="活动地点" placeholder="请输入" input-align="right"/>
      <van-cell title="开始时间" :value="dataForm.beginTime || '请选择'" @click="beginDateShow = !beginDateShow"
                :value-class="{'value-common':!dataForm.beginTime}" is-link />
      <van-cell title="结束时间" :value="dataForm.endTime || '请选择'"   @click="endDateShow = !endDateShow"
                :value-class="{'value-common':!dataForm.endTime}" is-link />
      <van-field clearable clear-trigger="always" v-model="dataForm.joinNum" label="参与人数" @blur="numBlur($event)" placeholder="请输入" input-align="right"/>
      <van-field clearable clear-trigger="always" v-model="dataForm.speaker" label="主讲人" placeholder="请输入" input-align="right"/>
      <van-cell title="活动类型" :value="typeStr || '请选择'"  @click="typeShow = !typeShow"
                :value-class="{'value-common':!typeStr}" is-link />
      <van-cell title="面向对象" is-link  :value="surveyObjectStr || '居民'"  @click="surveyObjectShow = !surveyObjectShow"/>
      <van-field clearable clear-trigger="always" v-model="dataForm.content" rows="3" :border="false" class="left" type="textarea" input-align="right" maxlength="50" placeholder="请输入"/>
      <van-row>
        <van-col :span="24">
          <div class="upload">
            <upload-file
                :file-list="dataForm.fileList"
                :del-btn-show="edit"
                :upload-btn-show="edit"
                @filesUpload="filesUpload"
                @delFile="delFile"
                :upload-path="uploadPath"
                :file-type="'imageOrVideo'"/>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from '@/utils/utils'
import {getVirtualDict, getBelongCommunity} from "@/utils/common";
import uploadFile from '@/components/upload/uploadFile'
export default {
  name: 'activity-add',
  data(){
    return{
      firstValue: [],
      uploadPath: 'files/activity/temporary',
      edit: true,
      secondValue: [],
      beginDateShow : false,
      endDateShow : false,
      typeShow : false,
      surveyObjectShow: false,
      childrenVisible: false,
      communityShow: false,
      typeStr: '',
      surveyObjectStr: '',
      communityStr: '',
      typeList: [],
      surveyObjectOptions: [],
      checkedValue: [],
      childrenValue: [],
      communityList: [],
      dataForm: {
        id: 0,//活动id
        title: '',  //活动名称
        address: '', //活动地址
        beginTime: '', //开始时间
        endTime: '', //结束时间
        joinNum: '', //参与人数
        typeOptions: [],//活动类型选择器下拉列表
        type: '', //活动类型
        fileList:[],
        content: '', //活动描述
        speaker: '',  //主讲人
        newFileMessages: [],
        deleteFiles: [],//删除的文件Id
        surveyObject: '1',  //默认面向居民
        orgId: 0, //所属机构
      }
    }
  },
  components:{topBar, uploadFile},
  methods:{
    filesUpload (files) {
      this.dataForm.newFileMessages = files
      this.dataForm.fileMessages = files
    },
    delFile (id) {
      this.dataForm.deleteFiles.push(id)
    },
    numBlur (val) {
      if(!/^[0-9]*[1-9][0-9]*$/.test(val.currentTarget.value)) {
        this.$toast.fail('请输入正整数的数字')
      }
    },
    getTypeOptions () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.typeList = data.dicts
        }
      },err=> {this.$toast.error({message:'活动类型获取失败'})})
    },


    // 获取活动   面向对象
    getActivitySurveyObjectOptions () {
      getVirtualDict('activitySurveyObject', (dicts) => {
          this.surveyObjectOptions = dicts
      })
    },

    // 完成
    submit(){
      this.dataForm.type = []
      if (this.childrenValue.length > 0) {
        this.childrenValue.map(item => {
          this.dataForm.type.push(item.value)
        })
      }
      if (this.checkedValue.length > 0) {
        this.checkedValue.map(item => {
          this.dataForm.type.push(item.value)
        })
      }
      this.dataForm.type = this.dataForm.type.join(',')
      if (this.dataForm.title == '') {return this.$toast.fail('失败:请先输入活动名称');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:请先输入活动地点');}
      if (this.dataForm.beginTime === '') {return this.$toast.fail('失败:请先选择开始时间');}
      if (this.dataForm.endTime === '') {return this.$toast.fail('失败:请先选择结束时间');}
      if (this.dataForm.joinNum === '') {return this.$toast.fail('失败:请先输入参与人数');}
      if (this.dataForm.speaker === '') {return this.$toast.fail('失败:请先输入主讲人');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:请先选择活动类型');}
      if (this.dataForm.content === '') {return this.$toast.fail('失败:请先输入活动详情');}
      if (this.dataForm.surveyObject === '请选择' || this.dataForm.surveyObject === '') {return this.$toast.fail('失败:请先选择面向对象');}
      this.$toast.loading({duration: 0,message: '提交中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/save'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm
        })
      }).then(({data})=> {
          this.$toast.clear()
        if (data.code == 0) {
          this.typeList = data.dicts
          this.$toast.success({message:'新增成功'})
          this.$router.go(-1)
        }else{this.$toast.fail({message:data.msg})}
      },err=> {this.$toast.error({message:'提交失败'})})
    },

    // 删除图片
    uploaddel(file,detail){
      let i = detail.index
      this.dataForm.newFileMessages.splice(i,1)
    },
    // 上传图片
    uploadRead(file){
      this.$toast.loading({
        message:"上传中...",
        forbidClick:true,
        duration:0,
        overlay:true
      });
      if(!Array.isArray(file)){
        file = [file]
      }
      file.forEach((item,index2)=>{
        let formdata = new FormData();
        formdata.append("file",item.file);
        formdata.append("path",'files/activity/temporary');
        this.$http({
          url:this.$http.adornUrl('/wxapp/file/upload'),
          method:'post',
          data:formdata
        }).then(({data})=>{
          if(index2 === file.length -1){
            this.$toast.clear()
          }
          if(data.code == 0){
            let uid = this.dataForm.newFileMessages.length
            data.fileMessage['uid'] = uid
            this.dataForm.newFileMessages.push(data.fileMessage)
            return true
          }else{
            this.$toast.fail(data.msg);
          }
        })
      })
    },

    // 选择时间的确认与取消
    typeConfirm(){
      this.typeShow = false
    },
    //
    toggle(index){
      this.$refs.checkboxes[index].toggle();
    },
    toggleChildren(index){
      this.$refs.childrenBoxes[index].toggle();
    },

    change(val){
      this.firstValue = val
        this.typeStr = val.map(function(item,index){
          return item.label}).concat(this.secondValue.map(item => {
          return item.label
        })).join(',')
      this.typeShow = true
    },
    childrenChange(val){
      this.secondValue = val
      this.typeStr = val.map(function(item,index){
        return item.label
      }).concat(this.firstValue.map(item=>{
        return item.label
      })).join(',')
    },

    beginDateConfim(value){
      let d = new Date(this.dataForm.endTime)
      if(this.dataForm.endTime != '' && d.getTime(d) < value.getTime(value) ){
        return this.$toast.fail("开始时间不能比结束时间大哦")
      }
      this.dataForm.beginTime = formatterDateTimeWithOutSec(value)
      this.beginDateShow =false

    },
    endDateConfim(value){
       let d = new Date(this.dataForm.beginTime)
       console.log(d.getTime);
       if(this.dataForm.beginTime != '' && d.getTime(d) > value.getTime(value)){
         return this.$toast.fail("结束时间不能比开始时间小哦")
       }
       this.dataForm.endTime = formatterDateTimeWithOutSec(value)
       this.endDateShow = false
    },

    // 选择参与对象
    surveyObjectConfirm(value){
      this.surveyObjectStr = value.label
      this.dataForm.surveyObject = value.value
      this.surveyObjectShow = false
    },

    // 选择所属机构
    communityConfim(value){
      this.communityStr = value.label
      this.dataForm.orgId = value.value
      this.communityShow = false
    },
  },
  created(){
    getBelongCommunity(this.$orgId, -1, (communityList) => {
      this.communityList = communityList
      this.communityList.unshift({
        label: this.$globalData.userInfo.streetName,
        value: Number(this.orgId)
      })
    })
    this.dataForm.orgId = Number(this.$globalData.userInfo.orgId)
    this.getTypeOptions()
    this.getActivitySurveyObjectOptions()

  }
}
</script>

<style lang="scss" scoped>
  .typeCheck {
    display: flex;
    height: 150px;
    line-height: 115px;
    padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
    }
  }
  .typeCheckBox .van-cell__title {
    margin-left: 20px;
  }
  .typeCheckBox .van-icon {
    position: absolute;
    right: 40px;
    bottom: 45%;
  }
  .children {
    margin-left: 50px;
  }
  .children .van-cell__value {
    margin-left: 10px;
  }
  .upload {
    margin-left: 50px;
  }
</style>>

